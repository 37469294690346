<template>
    <div class="form-group">
        <label>{{ label }}</label>
        <div class="input-group mb-3">
            <select class="form-control"
                :name="name"
                v-on:change.prevent="onChange">
                <option
                    v-for="item in items"
                    :key="item.name"
                    :value="item.name">{{ item.name }}</option>
            </select>
        </div>
        <div class="input-group mb-3">
            <input disabled=""
                v-if="customInputName"
                :name="customInputName" type="text" class="form-control" :placeholder="placeholderText" maxlength="20">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        name: String,
        items: Array,
        customInputName: String,
        customInputValue: String,
        placeholderText: String,
    },
    methods: {
        onChange(e) {
            const selector = `input[name=${this.customInputName}]`;
            const el = this.$el.querySelector(selector);
            if (e.target.value === this.customInputValue) {
                // enable type_custom
                el.removeAttribute('disabled');
                el.setAttribute('required', '');
            } else {
                // disable type_custom
                el.setAttribute('disabled', '');
                el.removeAttribute('required');
            }
        }
    }
}
</script>
