<template>
       <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="card card-primary card-outline card-outline-tabs mt-3">
                    <div class="card-header p-0">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" role="tab" aria-selected="false">기관 정보 수정</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" role="tab" aria-selected="true">업체 정보 수정</a>
                            </li>
                        </ul>
                    </div>
                    <form @submit.prevent="onSubmit">
                        <input type="hidden" name="id" :value="org.id">
                        <input type="hidden" name="_method" value="PATCH">
                        <div class="card-body">
                            <div class="mt-4 mb-2">
                                <h4>기관 분류</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">소속 인덱스</label>
                                        <select
                                            name="op_org"
                                            class="form-control"
                                            v-model="org.op_org"
                                        >
                                            <option
                                                v-for="item in OpOrgs"
                                                :key="item.name"
                                                :value="item.name"
                                            >
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">소속 건물</label>
                                        <select
                                            name="building"
                                            class="form-control"
                                            v-model="org.building"
                                            @change.prevent="
                                                onBuildingChange
                                            "
                                        >
                                            <option
                                                v-for="item in buildings"
                                                :key="item.name"
                                                :value="item.name"
                                            >
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">지역 분류</label>
                                        <select
                                            name="area"
                                            class="form-control"
                                            v-model="org.area"
                                        >
                                            <option value="분당구"
                                                >분당구</option
                                            >
                                            <option value="수정구"
                                                >수정구</option
                                            >
                                            <option value="중원구"
                                                >중원구</option
                                            >
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">운영 상태</label>
                                        <select
                                            name="status"
                                            class="form-control"
                                            v-model="org.status"
                                        >
                                            <option value="show_info"
                                                >지도 노출</option
                                            >
                                            <option value="hidden"
                                                >지도에서 숨김</option
                                            >
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 mb-2">
                                <h4>기관 기본 정보</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">기관명</label>
                                        <input
                                            name="name"
                                            type="text"
                                            class="form-control"
                                            maxlength="50"
                                            required
                                            v-model="org.name"
                                        />
                                    </div>
                                </div>
                                <div class="form-group col-sm-12">
                                    <div class="input-group mt-3">
                                        <label class="col-sm-2 col-form-label">주소</label>
                                        <div class="row col-sm-10">
                                            <input
                                                name="address_base"
                                                class="form-control"
                                                placeholder="기본주소"
                                                required
                                                v-model="org.address_base"
                                            />
                                            <input
                                                name="address_detail"
                                                class="form-control mt-2"
                                                placeholder="상세주소"
                                                maxlength="50"
                                                v-model="org.address_detail"
                                            />
                                            <div class="row mt-2">
                                                <label class="col-sm-4 col-form-label">단독 기관 지도 위치 정보</label>
                                                <div class="col-sm-3">
                                                    <input
                                                        class="form-control"
                                                        name="position_x"
                                                        placeholder="X좌표"
                                                        required
                                                        v-model="org.position_x"
                                                    />
                                                </div>
                                                <div class="col-sm-3">
                                                    <input
                                                        class="form-control"
                                                        name="position_y"
                                                        placeholder="Y좌표"
                                                        required
                                                        v-model="org.position_y"
                                                    />
                                                </div>
                                                <div class="col-sm-2">
                                                    <input
                                                        class="form-control"
                                                        name="display_order"
                                                        placeholder="표시 순서"
                                                        v-model="org.display_order"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">대표연락처</label>
                                        <input
                                            name="telephone"
                                            class="form-control"
                                            placeholder="예) 031-111-2222~3"
                                            maxlength="20"
                                            v-model="org.telephone"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">업체소개</label>
                                        <select class="form-control col-sm-2"
                                            name="show_companies"
                                            v-model="org.show_companies">
                                            <option value="0">없음</option>
                                            <option value="1">있음</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">홈페이지</label>
                                        <select class="form-control col-sm-2"
                                            name="show_homepage"
                                            v-model="org.show_homepage">
                                            <option value="0">없음</option>
                                            <option value="1">있음</option>
                                        </select>
                                        <input
                                            name="homepage"
                                            class="form-control ml-1 col-sm-10"
                                            v-model="org.homepage"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">3D메타포트 링크</label>
                                        <select class="form-control col-sm-2"
                                            name="show_matterport"
                                            v-model="org.show_matterport">
                                            <option value="0">없음</option>
                                            <option value="1">있음</option>
                                        </select>
                                        <input
                                            name="matterport_url"
                                            class="form-control ml-1 col-sm-10"
                                            v-model="org.matterport_url"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button
                                class="btn btn-primary float-right"
                                type="submit"
                            >
                                저장하기
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
       </section>
</template>

<script>
export default {
    async mounted() {
        const that = this;
        const id = this.$route.params.id;
        await window.axios.request({
            url: '/sanctum/csrf-cookie',
            method: 'get',
        }).then(async function () {
            return window.axios.get('/api/org/' + id)
            .then(function (response) {
                this.org = response.data;
                this.addressBaseOriginal = this.org.address_base;
            }.bind(that))
            .catch(function (e) {
                console.error(e);
                alert('잠시 후 다시 시도해주세요.');
            });
        }).catch(function () {
            alert('로그인해주세요');
            window.location.href = '/login';
        });
        if (this.org.building !== null) {
            document.querySelector('select[name=building]')
                .dispatchEvent(new Event('change'));
        } else {
            this.org.building = '단독 기관';
        }
    },
    data() {
        return {
            OpOrgs: this.$root.$data.store.getOpOrgs(),
            buildings: this.$root.$data.store.getBuildings(),
            org: {},
            addressBaseOriginal: '',
        };
    },
    methods: {
        onBuildingChange(e) {
            // console.log(e);
            const target = e.target;
            const currentValue = target.value;
            const defaultBuildingName = this.$data.buildings
                .filter(function(item) {
                    return !!item.default;
                })
                .pop().name;
            const positionX = document
                    .querySelector("input[name=position_x]");
            const positionY = document
                    .querySelector("input[name=position_y]");
            const displayOrder = document
                    .querySelector("input[name=display_order]");
            if (currentValue !== defaultBuildingName) {
                // disable position X/Y form
                positionX.setAttribute("disabled", "");
                positionX.removeAttribute('required');
                positionY.setAttribute("disabled", "");
                positionY.removeAttribute("required");
                displayOrder.setAttribute("disabled", "");
                displayOrder.removeAttribute("required");
                // copy address_base from building
                const building = this.$data.buildings.filter(function (building) {
                    return building.name === currentValue;
                }).pop();
                if (building.address_base) {
                    this.$data.org.address_base = building.address_base;
                }
            } else {
                // enable position X/Y form
                positionX.removeAttribute("disabled");
                positionX.setAttribute("required", '');
                positionY.removeAttribute("disabled");
                positionY.setAttribute("required", '');
                displayOrder.removeAttribute("disabled");
                displayOrder.setAttribute("required", "");
                // copy address_base from data
                this.$data.org.address_base = this.$data.addressBaseOriginal;
            }
        },
        onSubmit(e) {
            // console.log(e);
            const id = this.$route.params.id;
            const formData = new FormData(e.target);
            if (!formData.get("address_base")) {
                alert("기본 주소를 입력하세요.");
                return false;
            }
            // 소속 건물이 단독 기관이고 지도 좌표가 없으면 에러
            if (formData.get('building') === '단독 기관' &&
            (!Number(formData.get('position_x')) || !Number(formData.get('position_y')))) {
                alert('지도 좌표를 입력해주세요.');
                return false;
            }
            // 'http(s)://' 없이 홈페이지 주소를 등록하려 하면 자동으로 'http://' 를 붙여주기
            let homepage = formData.get('homepage');
            if (homepage !== '' && !/^https?:\/\//.test(homepage)) {
                formData.set('homepage', 'http://' + homepage);
            }
            window.axios({
                url: '/sanctum/csrf-cookie',
                method: 'get',
            }).then(function () {
                window.axios({
                    url: "/api/org/" + id,
                    method: "post",
                    data: formData
                })
                .then(function() {
                    alert('기관 정보가 정상적으로 수정되었습니다.');
                    window.location.href = '/admin/org/list';
                })
                .catch(function() {
                    alert('잠시 후 다시 시도해 주세요.\n\n계속 동일한 현상이 발생하면 담당자에게 문의해주세요.');
                    return false;
                });
            })
            .catch(function () {
                alert('로그인해주세요');
                window.location.href = '/login';
            });
        }
    }
}
</script>
