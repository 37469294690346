require('./bootstrap');

import Vue from 'vue';
// import Vuex from 'vuex';
import VueRouter from "vue-router";

window.Vue = Vue;
// Vue.use(Vuex);
Vue.use(VueRouter);

import router from './admin/routes';

(async function () {
    const opOrgs = await window.axios({
        url: '/api/config/opOrgs'
    })
        .then(function (response) {
            const { data } = response;
            return data;
        })
        .catch(function (e) {
            console.error(e);
        });

    const buildings = await window.axios({
        url: '/api/config/building'
    })
        .then(function (response) {
            const { data } = response;
            data.push({
                name: '단독 기관',
                default: true,
            });
            return data;
        })
        .catch(function (e) {
            console.error(e);
        });

    const store = {
        state: {
            buildings,
            opOrgs,
        },
        getBuildings() {
            return this.state.buildings.sort((a, b) => {
                return a.name > b.name;
            });
        },
        getOpOrgs() {
            return this.state.opOrgs;
        }
    };

    new Vue({
        router,
        el: '#app',
        data: {
            store
        },
    });
})();
