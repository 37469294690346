<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card mt-3">
                    <div class="card-header"><h3>공지사항 등록</h3></div>

                    <form method="post" action="/api/notice" @submit.prevent="onSubmit">
                        <div class="card-body">
                            <h4>내용 입력 및 수정</h4>
                                <div class="text-area">
                                    <textarea class="form-control" name="message" maxlength="200" placeholder="내용 입력" v-model="notice.message" required>
                                    </textarea>
                                    <div class="length mr-2 float-right">{{ notice.message !== null ? notice.message.length : 0 }}/{{ maxLength }}</div>
                                </div>
                        </div>

                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary float-right">저장하기</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.text-area {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding-bottom: 24px;
    textarea {
        border: 0;
    }
}
</style>

<script>
    export default {
        data() {
            return {
                notice: {
                    message: null,
                },
                textLength: 0,
                maxLength: 200,
            }
        },
        methods: {
            onSubmit() {
                const that = this;
                if (this.$data.notice.message.trim() === '') {
                    alert('내용은 비워둘 수 없습니다');
                    return false;
                }
                window.axios({
                    url: '/sanctum/csrf-cookie',
                    method: 'get',
                }).then(function () {
                    window
                        .axios({
                            url: "/api/notice",
                            method: "post",
                            data: that.$data.notice
                        })
                        .then(function() {
                            alert('내용이 정상적으로 저장되었습니다.');
                            window.location.href = '/admin/notice';
                            return true;
                        })
                        .catch(function() {
                            alert('잠시 후 다시 시도해 주세요.\n\n계속 동일한 현상이 발생하면 담당자에게 문의해주세요.');
                            return false;
                        });
                })
                .catch(function () {
                    alert('로그인해주세요');
                    window.location.href = '/login';
                });
            }
        },
        mounted() {
            const that = this;
            window.axios.get('/api/notice')
            .then(function (response) {
                that.$data.notice = response.data;
            })
        }
    }
</script>
