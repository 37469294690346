<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary card-outline card-outline-tabs mt-3">
                    <div class="card-header p-0">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <router-link class="nav-link active" to="/admin/org/list" role="tab" aria-selected="true">기관 목록</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/admin/company/list" role="tab" aria-selected="false">업체 목록</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body table-responsive text-nowrap">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        연번
                                    </th>
                                    <th>
                                        수정
                                    </th>
                                    <th>
                                        인덱스
                                    </th>
                                    <th>
                                        지역
                                    </th>
                                    <th>
                                        건물
                                    </th>
                                    <th>
                                        기관명
                                    </th>
                                    <th>
                                        운영 상태
                                    </th>
                                    <th>
                                        등록일시
                                    </th>
                                    <th>
                                        수정일시
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(org, idx) in orgs"
                                    :key="idx">
                                    <td>
                                        {{ org.id }}
                                    </td>
                                    <td>
                                        <router-link tag="a" :to="`/admin/org/modify/${org.id}`">[수정]</router-link>
                                    </td>
                                    <td>
                                        {{ org.op_org }}
                                    </td>
                                    <td>
                                        {{ org.area }}
                                    </td>
                                    <td>
                                        {{ org.building }}
                                    </td>
                                    <td>
                                        {{ org.name }}
                                    </td>
                                    <td>
                                        {{ getLocaleStatusString(org.status) }}
                                    </td>
                                    <td>
                                        {{ new Date(org.created_at).toLocaleDateString() }}
                                    </td>
                                    <td>
                                        {{ new Date(org.updated_at).toLocaleDateString() }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer clearfix">
                        <ul class="pagination pagination-sm m-0 justify-content-center">
                            <li class="page-item"
                            v-for="link in links"
                            :key="link.label"
                            :class="(link.active ? 'active' : '')"
                            v-on:click="onClickPage">
                                <router-link tag="a" class="page-link" v-if="link.url" :to="link.url" v-html="link.label">{{ link.label }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    async mounted() {
        const that = this;
        await window.axios.request({
            url: '/sanctum/csrf-cookie',
            method: 'get',
        }).then(async function () {
            let page = 1;
            if (that.$route.query.page) {
                page = Number(that.$route.query.page);
            }
            return that.getList(page);
        }).catch(function () {
            alert('로그인해주세요');
            window.location.href = '/login';
        });
    },
    data() {
        let orgs = [];
        let links = [];
        let first_page_url = '';
        let last_page_url = '';

        const data = {
            orgs,
            links,
            first_page_url,
            last_page_url,
        };
        // console.debug(data);
        return data;
    },
    methods: {
        onClickPage(e) {
            const page = Number(e.target.href.split(/page=/)[1]);
            this.getList(page);
        },
        async getList(page) {
            const that = this;
            return window.axios.get('/api/orgs?page='+ page +'&asc=desc')
            .then(function (response) {
                // console.debug({ response });
                const { data } = response.data;
                that.orgs = data;
                that.links = response.data.links;
                that.first_page_url = response.data.first_page_url;
                that.last_page_url = response.data.last_page_url;
            })
            .catch(function (e) {
                console.error(e);
                alert('잠시 후 다시 시도해주세요.');
            })
        },
        getLocaleStatusString: function (status) {
            switch (status) {
                case 'show_info':
                    return '지도 노출';
                case 'hidden':
                    return '지도에 숨김';
            }
            return '';
        }
    }
}
</script>
