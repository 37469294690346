import VueRouter from 'vue-router';

const routes = [
    {
        path: '/admin',
        component: require('./page/Dashboard').default
    },
    {
        path: '/admin/notice',
        component: require('./page/Notice').default
    },
    {
        path: '/admin/org/register',
        component: require('./page/organization/Register').default
    },
    {
        path: '/admin/org/list',
        component: require('./page/organization/List').default
    },
    {
        path: '/admin/org/modify/:id',
        component: require('./page/organization/Modify').default
    },
    {
        path: '/admin/company/register',
        component: require('./page/company/Register').default
    },
    {
        path: '/admin/company/list',
        component: require('./page/company/List').default
    },
    {
        path: '/admin/company/modify/:id',
        component: require('./page/company/Modify').default
    },
    {
        path: '/admin/*',
        redirect: '/admin'
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
