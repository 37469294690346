<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="card card-primary card-outline card-outline-tabs mt-3">
                    <div class="card-header p-0">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" role="tab" aria-selected="false">기관 정보 수정</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link active" role="tab" aria-selected="true">업체 정보 수정</a>
                            </li>
                        </ul>
                    </div>
                    <form v-on:submit.prevent="onSubmit">
                        <input type="hidden" name="_method" value="PATCH">
                        <input type="hidden" name="delete_logo" value="0">
                        <div class="card-body">
                            <div class="mt-4 mb-2">
                                <h4>업체 소속 기관</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">소속 기관 선택</label>
                                        <select
                                            name="org"
                                            class="form-control"
                                            v-model="company.org"
                                        >
                                            <option
                                                v-for="item in orgs"
                                                :key="item.name"
                                                :value="item.name"
                                            >
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 mb-2">
                                <h4>업체 이미지 정보</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">로고 이미지</label>
                                        <div class="custom-file">
                                            <input id="logoFile" type="file"
                                                name="logo"
                                                class="custom-file-input"
                                                @change.prevent="onFileChange"
                                            >
                                            <label for="logoFile" class="custom-file-label"></label>
                                        </div>
                                        <button type="button"
                                            class="btn btn-secondary ml-1"
                                            @click="onClickDelete"
                                        >
                                            삭제
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 mb-2">
                                <h4>기관 기본 정보</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">업체 운영 상태</label>
                                        <select
                                            name="status"
                                            class="form-control"
                                            v-model="company.status"
                                        >
                                            <option value="show"
                                                >리스트 노출</option
                                            >
                                            <option value="hidden"
                                                >비공개</option
                                            >
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">업체명</label>
                                        <input
                                            name="name"
                                            type="text"
                                            class="form-control"
                                            maxlength="50"
                                            required
                                            v-model="company.name"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-from-label">업체 소개</label>
                                        <textarea
                                            name="description"
                                            class="form-control"
                                            v-model="company.description"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-12">
                                    <div class="input-group">
                                        <label class="col-sm-2 col-form-label">홈페이지</label>
                                        <select name="show_homepage" class="col-sm-2 form-control"
                                            v-model="company.show_homepage">
                                            <option value="0">없음</option>
                                            <option value="1">있음</option>
                                        </select>
                                        <input
                                            name="homepage"
                                            class="ml-1 col form-control"
                                            v-model="company.homepage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button
                                class="btn btn-primary float-right"
                                type="submit"
                            >
                                저장하기
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.custom-file-input:lang(en) ~ .custom-file-label::after,
.custom-file-label::after {
    content: '파일 선택';
}
</style>

<script>
export default {
    mounted() {
        const id = this.$route.params.id;
        window.axios.get('/api/orgs?limit=9999')
        .then(function (response) {
            this.orgs = response.data.data;
        }.bind(this))
        .catch(function (e) {
            if (e.message) {
                console.error(e);
            }
            alert('잠시 후 다시 시도해주세요.');
        });
        window.axios.get('/api/company/' + id)
        .then(function (response) {
            this.company = response.data;
            if (this.company.description) {
                this.company.description = this.company.description.replace(/<br ?\/?>/, "\n");
            }
            if (this.company.logo_url) {
                const paths = this.company.logo_url.split('/');
                document.querySelector('label[for=logoFile]')
                    .textContent = paths[paths.length - 1];
            }
        }.bind(this))
        .catch(function (e) {
            if (e.message) {
                console.error(e);
            }
            alert('잠시 후 다시 시도해주세요.');
        })
    },
    data() {
        return {
            orgs: [],
            company: {},
        };
    },
    methods: {
        onClickDelete(e) {
            const fileInput = document.querySelector('input[type=file]');
            const label = document.querySelector('label[for=logoFile]');
            const deleteLogo = document.querySelector('input[name=delete_logo]');
            // reset file input
            if (fileInput.value !== '') {
                fileInput.value = '';
                if (this.company.logo_url) {
                    const paths = this.company.logo_url.split('/');
                    label.textContent = paths[paths.length - 1];
                }
                deleteLogo.value = '0';
            } else if (label.textContent !== '') {
                // delete current logo
                deleteLogo.value = '1';
                label.textContent = '';
            }
        },
        onFileChange(e) {
            const files = e.target.files;
            if (files.length) {
                document.querySelector('label[for=logoFile]')
                .textContent = files[0].name;
            }
        },
        onSubmit(e) {
            const id = this.$route.params.id;
            const formData = new FormData(e.target);
            // 'http(s)://' 없이 홈페이지 주소를 등록하려 하면 자동으로 'http://' 를 붙여주기
            let homepage = formData.get('homepage');
            if (homepage !== '' && !/^https?:\/\//.test(homepage)) {
                formData.set('homepage', 'http://' + homepage);
            }
            window.axios({
                url: '/sanctum/csrf-cookie',
                method: 'get',
            }).then(function () {
                window
                    .axios({
                        url: "/api/company/" + id,
                        method: "post",
                        data: formData
                    })
                    .then(function() {
                        alert('업체 정보가 정상적으로 수정되었습니다.');
                        window.location.href = '/admin/company/list';
                        return true;
                    })
                    .catch(function() {
                        alert('잠시 후 다시 시도해 주세요.\n\n계속 동일한 현상이 발생하면 담당자에게 문의해주세요.');
                        return false;
                    });
            })
            .catch(function () {
                alert('로그인해주세요');
                window.location.href = '/login';
            });
        }
    }
};
</script>
